import { Button, TextField, Toolbar, Typography } from "@material-ui/core"
import { Auth } from "aws-amplify"
import { navigate } from "gatsby"
import React from "react"
import { useLocation } from "@reach/router"
import HervePaper from "./HervePaper"
import AppBar from "./AppBar"
import Footer from "./Footer"
import Lottie from "react-lottie-player"
import { restUnauth } from "../services/rest"
import lottieJson from "../lottie/Andrew_Mckay-Done.json"

export default function FirstSignUp({ email, pwd }) {
  console.log({ email, pwd })
  const location = useLocation()
  console.log("loc", location.search)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState()
  const [formValues, setFormValues] = React.useState({
    email,
    password: `${pwd}${location.search}`,
    newPassword: "",
  })
  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate("/dashboard")
      }, 5000)
    }
  }, [success])
  const handleChange = key => event => {
    const value = event.target.value
    setFormValues(s => ({ ...s, [key]: value }))
  }

  const onPasswordUpdated = async user => {
    // at this time the user is logged in if no MFA required
    console.log("password updated", user)
    await restUnauth("post", "/open/confirmSignUp", {
      username: user.username,
    })
    setSuccess(true)
  }

  const onSignedIn = user => {
    const { newPassword } = formValues
    console.log({ user })
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword // the new password
      )
        .then(onPasswordUpdated)
        .catch(e => {
          console.warn(e)
          setError("Le mot de passe n'a pas pu être mis à jour")
        })
    } else {
      // other situations
    }
  }
  const signUp = () => {
    const { email, password } = formValues
    console.log(formValues)
    return Auth.signIn(email, password)
      .then(onSignedIn)
      .catch(e => {
        console.warn(e)
        setError("Le nom d'utilisateur et le mot de passe ne correspondent pas")
      })
  }
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        background: "rgb(238,238,243)",
      }}
    >
      <AppBar />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Toolbar />
        {success ? (
          <HervePaper style={{ alignItems: "center" }}>
            <Lottie
              loop={false}
              animationData={lottieJson}
              play
              style={{ width: 200 }}
            />
            <Typography variant="h5" align="center">
              Votre mot de passe a bien été mis à jour. Vous allez être redirigé
              vers votre espace personnel
            </Typography>
          </HervePaper>
        ) : (
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: 320,
            }}
          >
            <HervePaper title="Choisissez votre mot de passe">
              {/* <TextField
              value={email}
              id="email"
              label="Addresse email"
              type="email"
              style={{ width: 320, paddingBottom: 20 }}
              disabled
            /> */}
              {/* <TextField
              value={`${pwd}${location.search}`}
              // onChange={handleChange("password")}
              id="password"
              label="Mot de passe provisoire"
              type="password"
              autoComplete="new-password"
              style={{ width: 320, paddingBottom: 20 }}
              disabled
            /> */}
              <TextField
                onChange={handleChange("newPassword")}
                variant="outlined"
                autoFocus
                id="newPassword"
                label="Mot de passe"
                type="password"
                // style={{ width: 320, paddingBottom: 20 }}
              />
              <Button
                variant="contained"
                disabled={
                  !formValues.newPassword || formValues.newPassword.length < 8
                }
                onClick={signUp}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                Valider
              </Button>
              <Typography>
                Votre mot de passe doit contenir au moins 8 caractères.
              </Typography>
              <Typography>
                Pour plus de sécurité, utilisez des majuscules, des minuscules,
                des chiffres et des caractères spéciaux.
              </Typography>
            </HervePaper>
            {error ? (
              <HervePaper title={error} style={{ color: "red" }}>
                {/* <Typography>
                Assurez-vous que vous avez bien utilisé le dernier lien reçu
              </Typography> */}
              </HervePaper>
            ) : null}
          </form>
        )}
      </div>
      <Footer />
    </div>
  )
}
