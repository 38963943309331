import React from "react"
import { Router } from "@reach/router"
import FirstSignUp from "../components/FirstSignUp"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "absolute",
    height: "100vh",
    width: "100%",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    // color: theme.palette.primary.main,
  },
  root: {
    position: "relative",
    display: "flex",
    flex: 1,
    overflow: "hidden",
    // flexDirection: "column",
    // height: "100%",
    margin: 0,
    padding: 0,
  },
}))

export default function Finalsignup() {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Router
            primary={false}
            style={{ display: "flex", flex: 1, flexDirection: "column" }}
          >
            <FirstSignUp path="/finalsignup/:email/:pwd" />
          </Router>
        </div>
      </div>
    </div>
  )
}
