import { makeStyles, Typography } from "@material-ui/core"
import React from "react"
import useIsMobile from "../utils/useIsMobile"

const useStyles = isMobile =>
  makeStyles(theme => ({
  subtitle: {
    margin: isMobile ? null : theme.spacing(2),
    marginBottom: isMobile && theme.spacing(2), 
    marginLeft: 0,
    fontSize: isMobile && "17px",
    fontWeight: isMobile && "bold"
  },
}))

export default function HervePaperTitle({ title, height, ...props }) {
  const isMobile = useIsMobile()
  const classes = useStyles(isMobile)()
  return title ? (
    <Typography
      variant="h4"
      className={classes.subtitle}
      style={{
        fontSize: height ? (isMobile ? null : "20px") : null,
        fontWeight: height && "bold",
      }}
      {...props}
    >
      {title}
    </Typography>
  ) : null
}
