import { makeStyles, Paper } from "@material-ui/core"
import React from "react"
import HervePaperTitle from "./HervePaperTitle"
import useIsMobile from "../utils/useIsMobile"

const useStyles = isMobile =>
  makeStyles(theme => ({
    paper: {
      margin: theme.spacing(isMobile ? 1 : 2),
      padding: theme.spacing(2),
      // color: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
    },
  }))

export default function HervePaper({
  title,
  height = false,
  children,
  titleProps,
  bookingPro = false,
  ...props
}) {
  const isMobile = useIsMobile()
  const classes = useStyles(isMobile)()
  return (
    <Paper
      className={classes.paper}
      style={{
        height: height ? "fit-content" : "",
        //width: height ? (isMobile ? null : "58%") : null,
        //width: bookingPro ? (isMobile ? null : "45%") : null,
        marginTop: isMobile
          ? props.ended
            ? null
            : height
            ? null
            : "30px"
          : null,
      }}
      elevation={5}
      {...props}
    >
      {title ? <HervePaperTitle title={title} {...titleProps} height /> : null}
      {children}
    </Paper>
  )
}
